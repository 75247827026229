<script>
import Content from '../dialogs/Content.vue';

export default {
  props: ['photo'],
  components: {
    Content,
  },
  data() {
    return {
      save: false,
      like: false,
      view: false,
      notice: false,
    };
  },
  computed: {
    page() {
      return this.$route.name;
    },
    isList() {
      if (['stream', 'history', 'boards'].includes(this.page)) {
        return true;
      }
      return false;
    },
    auth() {
      return this.$store.getters['token/auth'];
    },
  },
  methods: {
    checkAuth() {
      if (this.auth) {
        console.log('checkAuth');
        return true;
      }
      this.$store.commit('needAuth');
    },
    saveThis() {
      if (this.checkAuth()) {
        this.$emit('save');
      }
    },
    likeThis() {
      if (this.checkAuth()) {
        this.like = this.like == false;
        this.$emit('like');

        this.$api
          .res('emotions')
          .post({
            id_photo: this.photo.id,
            value: this.like ? 1 : 0,
          })
          .then(({data}) => {});
      }
    },
  },
};
</script>

<template>
  <div class="image-options">
    <div>
      <div class="btn btn-dark btn-sm mr-1"
       @click="saveThis"
       :disabled="save">
        <i class="far fa-folder-open"></i>
        Сохранить
      </div>

      <div class="btn btn-sm btn-outline-danger" v-if="isList"
       @click="likeThis" >
        <i class="fa-heart" :class="like ? 'fas' : 'far'"></i>
        Like
      </div>

      <div class="btn btn-sm btn-outline-dark" v-else
       @click="likeThis" >
        <i class="fas fa-arrow-up"></i>
        Поднять
      </div>
    </div>

    <div>
      <div class="btn btn-outline-secondary btn-sm" v-if="isList"
       @click="$emit('bad')" >
        <i class="far fa-thumbs-down"></i>
      </div>
      <div class="btn btn-outline-secondary btn-sm" v-else
       @click="$emit('bad')" >
        <i class="far fa-trash-alt"></i>

      </div>
    </div>

    <Content @close="$emit('close')" v-if="notice">
      <div class="">
        Нужна Регистрация
      </div>
    </Content>
  </div>
</template>

<style lang="less">
.image-options {
  margin: 0 auto 15px;
  padding: 0 0px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .btn:focus {
    outline: none !important;
  }
}
</style>
