<script>

export default {
  props: ['success', 'failed', 'expired'],
  data() {
    return {
      sitekey: '6Lezl4kUAAAAALjME9A8xc4VA6D-QaFk0lNq54FJ',
      // sitekey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
      widgetId: null,
      show: true,
      error: false,
      expand: false,
    };
  },
  // watch: {
  //   widgetId() {
  //
  //   },
  // },
  mounted() {

  },
  methods: {
    onload() {
      this.render();
    },
    execute() {
      return global.grecaptcha.execute(this.widgetId, {action: 'homepage'}).then((token) => {
        this.reset();
        return token;
      });
    },
    reset() {
      if (global.grecaptcha && this.widgetId) {
        this.error = false;
        global.grecaptcha.reset(this.widgetId);
      }
    },
    render() {
      this.show = true;
      if (this.widgetId === null && global.grecaptcha) {
        this.widgetId = global.grecaptcha.render('g-recaptcha', {
          sitekey: this.sitekey,
          size: 'invisible',
          badge: 'inline',
          'expired-callback': this.onError,
          'error-callback': this.onError,
        });
      }
      // this.execute();
    },
    onError() {
      this.error = true;
    },
  },
};
</script>

<template>
  <div class="recaptcha-widget">
    <div id="g-recaptcha" class="g-recaptcha"></div>

    <div class="notation">
      Защита от спама
      <a href="https://www.google.com/recaptcha" target="_blank" rel="nofollow">
        reCAPTCHA
      </a> <i>·</i>
      <a rel="nofollow" href="https://www.google.com/intl/ru/policies/privacy/" target="_blank" class="notation__link">
        Конфиденциальность
      </a> <i>·</i>
      <a rel="nofollow" href="https://www.google.com/intl/ru/policies/terms/" target="_blank" class="notation__link">
        Условия использования
      </a>
    </div>
    <div v-if="error" @click="reset()">
      Во время проверки Google reCAPTCHA произошла ошибка или время ожидания истекло. Повторите при необходимости.
    </div>
  </div>
</template>

<style lang="less">
.recaptcha-widget {
  .g-recaptcha {
    display: none;
  }
  .notation {
    text-align: center;
    margin: 10px auto;
    margin-top: 15px;
    max-width: 750px;
    color: #666666;
    font-size: 10px;

    &__link {
      font-size: 10px;
      color: #666666;
      cursor: pointer;
      text-decoration: none;
    }
  }
}

</style>
