<script>
export default {
  props: ['path', 'color', 'isStatic'],
  data: () => ({}),
  computed: {
    source() {
      const {host} = this.$api.res('images');
      return `url(${host}${this.path})`;
    },
    background() {
      return {
        backgroundImage: this.source,
      };
    },
    avatar() {
      return {
        backgroundImage: this.source,
        backgroundColor: this.color,
      };
    },
  },
};
</script>

<template>
  <!-- HEADER -->
  <div class="header-image" :style="background">
    <div class="header-image__close" @click="$emit('close')" v-show="!isStatic">
      <i class="fas fa-times"></i>
    </div>
    <div class="header-image__image" :style="avatar"></div>
  </div>
  <!-- HEADER -->
</template>

<style lang="less">
.header-image {
  height: 170px;
  background-color: #b4c1d0;
  background-position: center;
  background-size: cover;
  margin: 0px 0px 0px;
  position: relative;
  margin-bottom: 0px;

  &__close {
    position: absolute;
    right: 0px;
    top: 0px;
    padding: 10px 15px;
    font-size: 18px;
    text-shadow: 0px 0px 10px rgba(#444, 0.8);
    color: #fff;
  }
  .header-navbar {
    min-height: 40px;
    display: flex;
    align-items: center;
    padding: 0 15px;
    background: rgba(#000, 0.3);
    color: #fff;
  }

  &__image {
    // display: none;
    width: 150px;
    height: 150px;
    border-radius: 200px;
    border: 2px solid #fff;
    background-color: #999;
    background-position: top;
    background-size: cover;
    box-shadow: 0px 0px 5px 2px rgba(#000, 0.3);
    position: absolute;
    bottom: 10px;
    left: 8px;
  }
  &__title {
    margin: 0px 0px 10px;
  }
}
</style>
