<script>
import Content from './Content.vue';

export default {
  props: [],
  components: {
    Content,
  },
  data: () => ({
    username: '',
    password: '',
  }),
  mounted() {},
  methods: {
    submit() {
      const data = {
        username: this.username,
        password: this.password,
      };
      this.$api
        .res('token')
        .post(data)
        .then(({data}) => {
          this.$store.dispatch('token/save', data);
          this.close();
        });
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<template>
  <Content :wrapped="true" :compact="true" @close="close">
    <div class="registration-form" :style="{maxWidth: '300px'}">

      <div class="form-group">
        <label for=""> <b>Логин</b> </label>
        <input type="text" class="form-control" v-model="username">
      </div>
      <div class="form-group">
        <label for=""> <b>Пароль</b> </label>
        <input type="text" class="form-control" v-model="password">
      </div>

      <div class="form-group">
        <div class="g-recaptcha" data-sitekey="6Le8Zn8UAAAAAIdFnQEDdaHsdBc_e0PGivcrfmz4"></div>
      </div>

      <div class="form-group">
        <button class="btn btn-danger" @click="submit()"> Продолжить </button>
        <button class="btn btn-link" @click="close"> Отмена </button>
      </div>
    </div>
  </Content>
</template>

<style lang="less">
.registration-form {
  .form-group:last-child {
    margin-bottom: 0;
  }
  // .input-group-text {
  //   min-width: 40px;
  //   justify-content: space-around;
  // }
}
</style>
