<script>
import _ from 'underscore';
import Tags from '@/components/card/Tags';

export default {
  props: ['images', 'reset'],
  components: {
    Tags,
  },
  data: () => ({
    // tags: [], // PopularTags,
  }),
  computed: {
    tags() {
      let tags = [];
      _.each(this.images, (element) => {
        var list = _.pluck(element.tags, 'tag');
        _.each(list, (tag) => {
          tags[tag.title] = tag;
        });
      });
      return _.values(tags);
    },
  },
  mounted() {
    // console.log(_.flatten(_.pluck(this.images, 'tags'), true));
  },
  methods: { },
};
</script>

<template>
  <transition name="slide">
    <div class="page-tag-list">
      <Tags :tags="tags" :reset="reset" :default="50"
        @select="$emit('select')" />
    </div>
</transition>
</template>

<style lang="less">
.page-tag-list {
  padding: 10px 5px;
  // border-left: 3px solid #000;
  // border-width: 0px 1px;
}
</style>
