<script>
export default {
  props: ['item', 'wait', 'error'],
  data: () => ({

  }),
  computed: {
    loaded() {
      return !this.error && this.item && this.item.alias;
    },
  },
}
</script>

<template>
  <div class="image-status">
    <div class="image-status__loader" v-if="loaded">
      <div class="wait-loader" v-show="wait">
        <i class="fas fa-hourglass-start"></i>
      </div>
    </div>
    <div class="image-status__placeholder" v-else>
      <i class="fas fa-exclamation"></i>
    </div>
  </div>
</template>

<style lang="less">
.image-status {

  .wait-loader {
    opacity: 0.7;
    background: #fff;
    padding: 5px 7px;
    border-radius: 2px;
    min-width: 30px;
    text-align: center;
    i {
      font-size: 18px;
    }
  }

  &__placeholder {
    position: relative;
    text-align: center;
    color: #999;
    // height: 300px;
    width: 100%;
    padding-top: 80%;
    i {
      font-size: 42px;
      position: absolute;
      top: 45%;
    }
  }

  &__loader {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
}
</style>
