<script>
import timestamp from '@/assets/libs/timestamp';

export default {
  props: ['auth'],
  data: () => ({
    left: 0,
    accent: 600,
    lifetime: 3600,
    trigger: 2590000,
    expires: 2592000,
    attempt: 0,
  }),
  mounted() {
    this.verify();
    this.timed();
    window.setInterval(this.timed, 3000);
  },
  computed: {
    normal() {
      return this.lifetime - this.accent;
    },
    warning() {
      return this.trigger - this.expires;
    },
    color() {
      return {
        'badge-primary': this.left > this.normal,
        'badge-success': this.left < this.normal && this.left > 0,
        'badge-secondary': this.left < 0 && this.left > this.warning,
        'badge-warning': this.left < this.warning && this.left > 0 - this.expires,
        'badge-danger': this.left < 0 - this.expires,
      };
    },
  },
  methods: {
    timed() {
      if (!this.auth) {
        return 0;
      }
      this.left = 0 - timestamp(-this.$store.state.token.exp);
      if (this.left < this.accent && this.attempt < 3) {
        this.update();
      }
    },
    verify() {
      if (!this.$api.default('key')) {
        this.update();
      }
    },
    update() {
      const refresh_token = this.$store.state.token.refresh;
      if (!refresh_token) {
        console.log('empty refresh_token');
        return 0;
      }
      this.$api.res('login/refresh')
        .load({refresh_token})
        .then(({data}) => {
          this.$store.dispatch('token/save', data);
          this.attempt = 0;
        });
      this.attempt += 1;
    },
  },
};
</script>

<template>
  <span class="authenticator" v-show="auth && left">
    <span class="badge" :class="color" @click="update()">{{left}}</span>
  </span>
</template>

<style lang="less">
.authenticator {
  top: -1px;
  position: relative;
  margin: 0 10px;
  opacity: 0.1;
}
</style>
