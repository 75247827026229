import timestamp from '@/assets/libs/timestamp';
import api from '@/config/api';
import Decoder from 'jwt-decode';

export default {
  namespaced: true,
  state: {
    token: '',
    username: '',
    roles: [],
    exp: null,
    iat: null,
    refresh: '',
    expires: '',
  },
  mutations: {
    load(state, token) {
      const data = Decoder(token);
      state.token = token;
      state.id = data.id;
      state.username = data.username;
      state.roles = data.roles;
      state.exp = data.exp;
      state.iat = data.iat;
    },
    refresh(state, token) {
      state.refresh = token;
    },
    logout(state) {
      state.token = null;
      state.refresh = null;
    },
    expires(state) {
      state.expires = timestamp(2592000);
    },
    auth(state, token) {
      const key = token || state.token || null;
      api.default('key', key);
      api.setAuthKey(key);
    },
  },
  actions: {
    save({commit}, data) {
      commit('load', data.token);
      commit('auth', data.token);
      commit('refresh', data.refresh_token);
      commit('expires');
    },
  },
  getters: {
    auth(state) {
      const time = timestamp();
      const {exp, expires, token} = state;
      if (!token || time > exp || time > expires) {
        return false;
      }
      return true;
    },
  },
};
