<script>
import HeaderImage from '@/components/modules/HeaderImage.vue';

export default {
  props: ['auth'],
  components: {
    HeaderImage,
  },
  data: () => ({
    user: {
      username: 'anon',
      id: 12345,
    },
  }),
  methods: {},
};
</script>

<template>
  <transition name="slide">
    <div class="user-header">
      <HeaderImage :path="null" :color="'#d74822'" :isStatic="true"/>
      <div class="profile-user">

        <div class="">
          <span class="user-info__name">{{user.username}}</span>
          <span class="user-info__id">#{{user.id}}</span>
        </div>

        <div class="profile-user__settings">
          <span class="text-dark" @click="$router.push({name: 'profile'})">
            <i class="fas fa-cog"></i>
            Настроить
          </span>
        </div>

      </div>

    </div>
  </transition>
</template>

<style lang="less">
.user-header {
  margin-top: 10px;
}
.profile-user {
  padding: 10px 15px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__settings {
  }

  .user-info {
    &__name {
      font-size: 22px;
      color: #444;
      margin-right: 5px;
    }
    &__id {
      color: #ccc;
      &:hover {
        color: #999;
      }
    }
    margin-bottom: 5px;
  }
}
</style>
