<script>
export default {
  props: ['rank', 'quality', 'width', 'height'],
  data: () => ({}),
};
</script>

<template>
  <div :class="'image-quality '+quality">
    <div class="image-summary">
      <span>
        {{width}}x{{height}}
      </span>
      <span class="image-summary__general" v-if="rank != 0">
        <!-- {{rank}} <i class="fas fa-angle-up" :class="rank > 0 ? 'fa-angle-up' : 'fa-angle-down'"></i> -->
      </span>
    </div>
  </div>
</template>

<style lang="less">
.image-summary {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  padding: 1px 10px 1px ;
  font-size: 14px;
  color: #fff;
  // margin-bottom: 15px;
  line-height: 1;
  // opacity: 0.5;
  &__general {
    padding-left: 1px;
  }
}

.image-quality {
  position: relative;
  background-color: #777;
  // &.huge {
  //   background-color: rgba(153, 51, 255, 0.3);
  // }
  // &.large {
  //   background-color: rgba(51, 153, 255, 0.5);
  // }
  // &.medium {
  //   background-color: #C2E0B2;
  // }
  // &.small {
  //   background-color: #edf6fd;
  // }
}
</style>
