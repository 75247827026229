<script>
// import _ from 'underscore';

export default {
  props: ['tags', 'edit', 'default', 'reset'],
  watch: {
    reset(val) {
      return val && this.collapse();
    },
    $route: 'collapse',
  },
  data: () => ({
    count: 0,
  }),
  mounted() {
    this.count = this.default;
  },
  computed: {
    list() {
      return this.tags.slice(0, this.count);
    },
    max() {
      return this.tags.length;
    },
  },
  methods: {
    // byCount() {
    //   return _.sortBy(this.tags, 'count').reverse();
    // },
    push(tag) {
      this.$emit('select', tag);
      this.$router.push({name: 'boards', params: {tag: tag.id, title: tag.title}});
    },
    more() {
      this.count += 50;
      this.$emit('more');
    },
    collapse() {
      this.count = this.default;
    },
  },
};
</script>

<template>
  <div class="image-tags" v-if="tags.length">
    <span class="image-tags__item" v-for="item in list" @click="push(item)" :key="item.id">
      {{item.title}}
    </span>
    <span class="image-tags__item more" v-show="count < max" @click="more">
      <i class="fas fa-chevron-right"></i>
    </span>

    <span class="image-tags__item edit" v-show="edit">
      <i class="fas fa-pen"></i>
    </span>

  </div>
</template>

<style lang="less">
.image-tags {
  padding: 0px 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  &__item {
    color: #444;
    font-size: 12px;
    border: 1px solid #ccc;
    background: #f5f5f5;
    padding: 5px 5px;
    border-radius: 3px;
    margin: 0 3px 4px 0px;
    display: inline-block;
    cursor: pointer;
    text-transform: uppercase;
    text-align: center;

    &.more,
    &.edit {
      min-width: 25.5px;
    }

    &.tag-variant {
      // background: #56b15f;
    }

    &.tag-select {
      background: #56b15f;
    }
  }
}
</style>
