export default {
  namespaced: true,
  state: {
    login: false,
    auth: false,
  },
  mutations: {
    open(state, key) {
      state[key] = true;
    },
    close(state, key) {
      state[key] = false;
    },
  },
};
