<script>
import TagsQueryList from './TagsQueryList.vue';
export default {
  props: ['order', 'rank', 'days', 'title'],
  components: {
    TagsQueryList,
  },
  data: () => ({
    pages: ['stream', 'history', 'boards'],
    expand: false,
    list: false,
    query: '',
  }),
  watch: {
    title(val) {
      this.query = val;
    },
  },
  computed: {
    page() {
      return this.$route.name;
    },
    enabled() {
      return this.pages.indexOf(this.page) >= 0;
    },
  },
  methods: {
    setDays(days) {
      this.days = days;
      this.$emit('days', days);
    },
    setRank(rank) {
      this.rank = rank;
      this.$emit('rank', rank);
    },
    append(title) {
      this.query += title;
    },
    listShow() {
      this.list = true;
      this.expand = true;
    },
    listClose() {
      // this.query = '';
      this.list = false;
      this.expand = false;
    },
    push(tag) {
      this.$router.push({name: 'boards', params: {tag: tag.id, title: tag.title}});
      this.query = tag.title;
      this.listClose();
    },
    bugfix(event) {
      this.query = event.target.value;
    },
  },
};
</script>

<template>
  <div class="image-filters">
    <div class="d-flex flex-nowrap" v-if="enabled"
      :class="expand ? 'expand-search' : 'form-inline'">
        <div class="btn-group mr-2" v-show="!expand && page == 'stream'">
          <div class="btn btn btn-outline-dark"
            :class="{active: order != 'history'}"
            @click="$router.push({
              name: page, params: { rank: 100, days: 100000 },
            })">
           <i class="fas fa-bolt"></i>
         </div>
          <div class="btn btn btn-outline-dark"
           :class="{active: order == 'history'}"
           @click="$router.push({
             name: page, params: { rank: 100, days: 100000 },
             query: {order: 'history'},
           })">
           <i class="fas fa-location-arrow"></i>
         </div>
        </div>

        <input class="form-control" type="text" @click="listShow"
          placeholder="" :value="query" @input="query = $event.target.value">

        <div class="btn btn-outline-secondary ml-1" v-if="expand" @click="listClose">
          <i class="fas fa-times"></i>
        </div>
    </div>

    <TagsQueryList v-if="list"
     :query="query" :simple="true" :auto="expand"
     @select="push" @append="append"/>

  </div>
</template>

<style lang="less">
.image-filters {
  background: #fff;
  padding: 10px;
  margin-bottom: 0px;

  input {
    min-width: 380px;
    @media (max-width: 576px) {
      min-width: inherit;
    }
  }

  .expand-search {
    display: flex;
  }

  .btn i {
    min-width: 16px;
  }

  input {
    text-transform: uppercase;

    &::placeholder {
      font-family: 'Font Awesome 5 Free', Arial;
      font-weight: 900;
      color: #999;
    }
  }
}
</style>
