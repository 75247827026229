<script>
import Tags from '@/components/card/Tags.vue';
export default {
  props: ['reset'],
  components: {
    Tags,
  },
  data: () => ({
    tags: [], // PopularTags,
  }),
  mounted() {
    this.count = this.default;
    this.$api
      .res('tags')
      .get({count: 150})
      .then(({data}) => {
        this.tags = data;
        this.$emit('show');
      });
  },
  methods: {},
};
</script>

<template>
  <transition name="slide">
    <div class="tags-popular">
      <Tags :tags="tags" :reset="reset" :default="50"
        @select="$emit('select')" />
    </div>
</transition>
</template>

<style lang="less">
.tags-popular {
  padding: 10px 5px 5px;
  border-left: 3px solid #000;
  // border-width: 0px 1px;
}
</style>
