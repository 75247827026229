<script>
import Header from './components/header/Header';
import Login from './components/dialogs/Login';
import UserMenu from './components/UserMenu';
import Promotion from './components/Promotion';
import Recaptcha from './components/modules/Recaptcha3v';

export default {
  name: 'app',
  data() {
    return {};
  },
  components: {
    Header,
    Login,
    UserMenu,
    Promotion,
    Recaptcha,
  },
  mounted() {
    global.recaptcha = this.$refs.recaptcha;
    global.recaptchaLoad = global.recaptcha.onload;
    this.$store.commit('token/auth');
  },
  methods: {
    auth() {
      return this.$store.getters['token/auth'];
    },
  },
};
</script>

<template>
  <div id="app">
    <div id="wrapper">
      <Header />

      <router-view></router-view>

      <Login v-if="$store.state.dialogs.login"
        @close="$store.commit('loginDialog', false)"/>

      <div style="text-align: center;">
        <!-- Go to www.addthis.com/dashboard to customize your tools -->
        <div class="addthis_inline_share_toolbox"></div>
      </div>

      <Recaptcha ref="recaptcha"/>

    </div>
    <Promotion v-if="!auth()"/>
    <UserMenu v-else/>

  </div>
</template>

<style lang="less">
@import '~bootstrap/dist/css/bootstrap.css';

body {
  margin: 0;
  background: #f2f2f2;
  font-family: Arial;
}

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-bottom: 60px;
}

#wrapper {
  margin: 0 auto;
  width: 100%;
  max-width: 600px;
  position: relative;
}

.board {
  margin: 0 auto;
  width: 100%;
  max-width: 600px;
  position: relative;

  &.content {
    background: #fff;
  }
  &.wrapped {
    padding: 10px 15px;
  }
  &.widget {
    margin-bottom: 10px;
  }
}
</style>
