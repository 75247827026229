<script>
export default {
  props: ['dark', 'compact'],
  data: () => ({}),
  methods: {
    style() {
      return {
        dark: this.dark,
      };
    },
  },
};
</script>

<template>
  <transition name="fade">
    <div class="standart-dialog" :class="{dark: dark}"
     @click.self="$emit('close')">
      <div class="standart-dialog__wrapper" :class="{compact: compact}"
       @click.self="$emit('close')">
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<style lang="less">
.standart-dialog {
  &.dark {
    background: rgba(#000, 0.95);
  }

  background: rgba(#fff, 0.95);

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  overflow-y: auto;
  z-index: 2;

  &__wrapper {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    max-width: 600px;
    position: relative;
    &.compact {
      max-width: 400px;
    }
  }

  &__activity {
    height: 100%;
    background: #fff;
    box-shadow: 0px 2px 2px rgba(#444, 0.5);
  }

  &__content {
    &.wrapped {
      padding: 15px 15px 25px;
    }

    // padding: 10px;
    padding-bottom: 10px;
    background: #fff;
    border-bottom: 0px solid #ccc;
    box-shadow: 0px 0px 2px 0px rgba(#000, 1);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}

.fade- {
  @speed: 0.1s;

  &enter-active,
  &leave-active {
    transition: all @speed;
  }

  &enter-to,
  &leave {
    opacity: 1;
  }

  &enter,
  &leave-to {
    opacity: 0;
  }
}
</style>
