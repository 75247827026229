<script>
import _ from 'underscore';

export default {
  props: [],
  data: () => ({
    scrolled: 0,
    triggerHeight: 1000,
  }),
  computed: {
    show() {
      return this.scrolled && (this.scrolled > this.triggerHeight);
    },
  },

  created() {
    global.addEventListener('scroll', this.scroll);
  },
  destroyed() {
    global.removeEventListener('scroll', this.scroll);
  },
  mounted() { },
  methods: {
    scroll: _.throttle(function () {
      this.scrolled = document.documentElement.scrollTop * 1;
    }, 400),
  },
};
</script>

<template>
  <div class="follow-top" @click="$emit('top')" v-show="show">
    <i class="fas fa-chevron-circle-up"></i>
  </div>
</template>

<style lang="less">
.follow-top {
  @font-size: 50px;

  position: fixed;
  top: 10px;
  color: #000;
  left: 50%;
  margin-left: -@font-size / 2;
  font-size: @font-size;
  line-height: 1;
  cursor: pointer;

  @color: #fff;
  @opacity: 0.2;
  // border-radius: 20px;
  // border: 2px solid @color;
  // box-shadow: 0 0 1px 1px rgba(@color, @opacity);
  opacity: @opacity;

  i {
    // background-color: @color;
    // border-radius: 20px;
    // line-height: 1;
  }
}
</style>
