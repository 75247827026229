const config = {
  development: {
    NET_DELAY: 1,
    API_URL: 'http://127.0.0.1:8000',
    IMG_CDN: 'http://127.0.0.1:8000',
  },
  production: {
    NET_DELAY: 0,
    API_URL: '//api.fapbook.me',
    IMG_CDN: '//cdn.fapbook.me',
  },
  get(env) {
    return this[env];
  },
};

const env = process.env.NODE_ENV || "production";

export default config[env];
