<script>
import PopularTags from '@/assets/data/PopularTags';
// import Prerequisite from '@/components/Prerequisite.vue';
import HeaderTagList from './HeaderTagList';
import HeaderMenu from './HeaderMenu';
import UserHeader from './UserHeader';

export default {
  props: [],
  components: {
    // Prerequisite,
    HeaderTagList,
    HeaderMenu,
    UserHeader,
  },
  watch: {
    $route: 'collapse',
  },

  data: () => ({
    profile: false,
    showTags: false,
    showUser: false,
  }),
  mounted() {
    setTimeout(() => {
      this.showTags = false;
    }, 4000);
  },
  computed: {
    auth() {
      return this.$store.getters['token/auth'];
    },
  },
  methods: {
    home() {
      this.collapse();
      this.$router.push('/');
    },
    collapse() {
      this.showTags = false;
    },
  },
};
</script>

<template>
  <div>
    <!-- <Prerequisite v-if="!auth"/> -->
    <div class="page-header">
      <div class="d-flex align-items-center justify-content-between">

        <div class="d-flex align-items-center justify-content-start">
          <span class="logo__home" @click="home">
            <b>Fap</b>book
          </span>
          <span class="tag-select" @click="showTags = (showTags == false)">
            <i class="fas" :class="showTags ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
          </span>
        </div>

        <HeaderMenu :auth="auth" />
      </div>

      <HeaderTagList :reset="showTags" v-show="showTags"
        @select="showTags = false"
        @show="showTags = true"/>


      <UserHeader :auth="auth" v-if="showUser"
        @hide="showUser = false" />

    </div>

  </div>
</template>

<style lang="less">
.page-header {
  max-width: 600px;
  color: #444;
  // text-align: center;
  margin: 0px auto;
  margin-top: 10px;
  margin-bottom: 10px;
  // padding: 0px 10px;
  // padding-left: 0px;
  position: relative;

  .logo__home {
    display: inline-block;
    cursor: pointer;
    font-size: 26px;
    font-weight: bolder;
    color: #fff;
    background: #000;
    padding: 1px 10px 2px;

    b {
      color: #dd2b00;
    }
  }

  .tag-select {
    margin-left: 5px;
    padding: 10px 10px 8px;
    cursor: pointer;
  }
}
</style>
