<script>
import _ from 'underscore';
import FollowTop from './FollowTop.vue';

export default {
  props: ['next', 'waiting'],
  components: {
    FollowTop,
  },
  watch: {
    // при изменениях маршрута запрашиваем данные снова
    $route: 'reset',
  },
  data: () => ({
    loadedCount: 0,
    limitLoaded: 3,
    triggerHeight: 2000, // pixel
    delay: 1500,
  }),

  created () {
    global.addEventListener('scroll', this.scroll);
  },
  destroyed () {
    global.removeEventListener('scroll', this.scroll);
  },

  methods: {
    reset() {
      this.loadedCount = 0;
      this.top(); //'smooth'
    },
    more() {
      if (!this.waiting && this.loadedCount < this.limitLoaded) {
        this.loadedCount += 1;
        this.$emit('more');
      }
    },
    top() {
      global.scrollTo({top: 0, behavior: 'smooth'});
    },
    force() {
      if (!this.waiting) {
        this.reset();
        this.$emit('force');
      }
    },

    scroll: _.throttle(function() {
      var scrollTop = document.documentElement.scrollTop * 1;
      var windowHeight = global.innerHeight * 1;
      var bodyHeight = document.documentElement.offsetHeight - windowHeight;

      if (bodyHeight < scrollTop + this.triggerHeight) {
        console.log({scrollTop, l: this.limitLoaded, c: this.loadedCount});
        this.more();
      }
    }, 700),
  },
};
</script>

<template>
  <div style="text-align: center;">


    <FollowTop @top="top"/>

    <div class="next-page">
      <button class="btn"
       :class="waiting ? 'btn-outline-dark' : 'btn-dark'"
       :disabled="waiting" @click="force()">
        <span v-if="!waiting">Больше фото</span>
        <span v-else>Загружаю...</span>
      </button>
      <button class="btn btn-outline-secondary">{{next}}</button>
    </div>
    <div class="hint-info">
      Сохраните лучшее порно или эротические фото для себя. Понравившиеся фотографии можно посмотреть в любой момент.
    </div>
  </div>
</template>

<style lang="less">
.next-page {
  margin-bottom: 10px;
  button {
    margin: 0 3px;
  }
}
.hint-info {
  max-width: 600px;
  margin: 0 auto 0px;
  padding-bottom: 10px;
}
</style>
