<script>
import BottomBar from './BottomBar';
import Registration from './dialogs/Registration.vue';

export default {
  props: [],
  components: {
    BottomBar,
    Registration,
  },
  data: () => ({
    // registration: false,
  }),
  // computed: {
  //   registration() {
  //     // return this.$store.state.dialogs.auth;
  //   },
  // },
  methods: {
    show() {
      this.$store.commit('needAuth');
    },
    close(){
      this.$store.commit('needAuth', false);
    },
  },
}
</script>

<template>
  <div>
    <BottomBar>
      <div class="registration-promo" @click="show">
        <button class="btn btn-danger">Начать</button>
        <div class="registration-promo__tagline">
          Простая, быстрая регистрация
        </div>
      </div>
    </BottomBar>

    <Registration v-if="$store.state.dialogs.auth"
     @close="close" />
  </div>
</template>

<style lang="less">
.registration-promo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 5px;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;

  &__tagline {
    padding-left: 10px;
  }
}
</style>
