<script>
import randomatic from 'randomatic';
import Content from './Content.vue';
import Loader from '../Loader.vue';

export default {
  props: ['registrationToken', 'recaptchaToken'],
  components: {
    Content,
    Loader,
  },
  data: () => ({
    username: 'anon',
    password: '',
    email: '',
  }),
  mounted() {
    this.save();
  },
  methods: {
    save() {
      this.$api.res('registration').new({
        key: this.registrationToken,
        token: this.recaptchaToken,
      }).then(({data}) => {
        console.log('saved', data);
        this.$store.dispatch('token/save', data);
      });
    },
  },
};
</script>

<template>
  <Content :wrapped="true" :compact="true" @close="$emit('close')">
    <div class="registration-hint">
      Создание профиля
    </div>

    <Loader/>
  </Content>
</template>

<style lang="less">

</style>
