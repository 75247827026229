<script>
import Authenticator from './Authenticator';
import Profile from '@/components/dialogs/Profile.vue';

export default {
  props: ['auth'],
  components: {
    Authenticator,
    Profile,
  },
  data: () => ({
    showMenu: false,
  }),
  computed: {},
  methods: {
    login() {
      this.$store.commit('loginDialog');
    },
    logout() {
      this.$store.commit('token/logout');
      console.log('logout', this.auth);
    },
  },
};
</script>

<template>
  <div class="">
    <div class="header-menu">

      <Authenticator :auth="auth"/>

      <div class="profile-menu" v-if="0">
        <div class="text-dark" @click="logout">
          Выйти
        </div>
      </div>

      <div class="adult-icon" v-if="!auth">

      </div>

      <div class="btn btn-outline-dark" v-if="!auth" @click="login">
        Войти
      </div>
      <div class="profile-button" @click="showMenu = true" v-else>
        <i class="fas fa-user-circle"></i>
      </div>

    </div>

    <Profile @logout="logout" v-if="showMenu" @close="showMenu = false"/>
  </div>
</template>

<style lang="less">
.header-menu {
  display: flex;
  align-items: center;
  div {
    margin: 0 3px;
  }

  .adult-icon {
    color: white;
    background: url('/adult50red.png');
    background-position: center;
    border-radius: 30px;
    background-size: contain;
    width: 34px;
    height: 34px;
    // opacity: .9;
    // box-shadow: 0 0 1px rgba(0, 0, 0, .8);
  }

  .profile {
    &-menu {
      display: flex;
      align-items: center;

      &__header {
        height: 55px;
        // border-bottom: 1px solid #ccc;
      }

      &__list {
        // padding: 10px 0;

        &-item {
          padding: 10px;
          border-top: 1px solid #e2e2e2;
          cursor: pointer;
          font-weight: bolder;
        }
      }
    }

    &-button {
      @size: 38px;
      position: relative;
      bottom: -1px;
      font-size: @size;
      line-height: 0;
      margin-right: 5px;
      i {
        background-color: #fff;
        border-radius: @size;
        line-height: 0.9;
      }
    }
  }
}

.slide- {
  @speed: 0.05s;

  &enter-active {
    transition: all @speed ease-out;
  }
  &leave-active {
    transition: all @speed ease-in;
  }

  &enter-to,
  &leave {
    max-height: 50px;
    overflow: hidden;
  }

  &enter,
  &leave-to {
    overflow: hidden;
    max-height: 0;
  }
}
</style>
