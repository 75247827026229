<script>
export default {
  props: [],
  data: () => ({

  }),
};
</script>

<template>
  <div class="simple-loader">
    <span>•••</span>
  </div>
</template>

<style lang="less">
.simple-loader {
  color: #999;
  font-size: 32px;
  text-align: center;
  padding: 10px;
}
</style>
