import Vue from 'vue';
import Router from 'vue-router';
import ImageList from '@/components/ImageList';

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/:page?',
      name: 'stream',
      component: ImageList,
      props: true,
    },
    {
      path: '/boards/:tag/:page?',
      name: 'boards',
      component: ImageList,
      props: true,
    },
    {
      path: '/collection/:page?',
      name: 'collection',
      component: ImageList,
      props: true,
    },
    {
      path: '/liked/:page?',
      name: 'liked',
      component: ImageList,
      props: true,
    },

    // {
    //   path: '/profile',
    //   name: 'profile',
    //   component: Profile,
    //   props: true,
    // },

    // {
    //   path: '/about',
    //   name: 'about',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
    // }
  ],
});
