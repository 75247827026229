import Vue from 'vue';
import App from './App.vue';
import store from './store';
import router from './config/router';
import rest from './plugins/RestAPI';
// import 'bootstrap/dist/css/bootstrap.min.css'

Vue.config.productionTip = false;

Vue.use(rest);

new Vue({
  data: {
    apiUrl: 'http://127.0.0.1:8000',
  },

  store,
  router,
  render: (h) => h(App),
}).$mount('#app');
