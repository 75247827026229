import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';

import token from './modules/token';
import dialogs from './modules/dialogs';
import images from './modules/images';

const vuexLocal = new VuexPersist({
  storage: global.localStorage,
});

Vue.use(Vuex);

export default new Vuex.Store({
  // state: {  },
  mutations: {
    loginDialog(state, show) {
      state.dialogs.login = show != false;
    },
    needAuth(state, show) {
      state.dialogs.auth = show != false;
      console.log('needAuth', state);
    },
  },
  actions: {
    clientWidth({state}, width) {
      state.clientWidth = width;
    },
  },
  modules: {
    token, // import('./modules/token'),
    dialogs,
    images,
  },
  plugins: [vuexLocal.plugin],
});
