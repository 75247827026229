<script>
import Template2 from './Template2.vue';

export default {
  props: ['dark', 'wrapped', 'compact'],
	components: {
	  Template2,
	},
  data: () => ({

  }),
  methods: {
  //
  }
}
</script>

<template>
  <Template2 :compact="compact" :dark="dark" @close="$emit('close')">
    <div class="standart-dialog__content" :class="{wrapped: wrapped}">
      <slot></slot>
    </div>
  </Template2>
</template>

<style lang="less">
.standart-dialog__content {
  h1 {
    font-size: 26px;
    // font-weight: bold;
  }
}
</style>
