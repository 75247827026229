<script>
import _ from 'underscore';
import config from '@/config/index';

export default {
  props: ['item', 'full', 'draft'],
  data: () => ({
    sources: {
      blur: {src: '', delay: 0.5, enabled: 1},
      blear: {src: '', delay: 0.5, enabled: 1},
      preview: {src: '', delay: 0, enabled: 1},
      overview: {src: '', delay: 0, enabled: 1},
    },
  }),
  watch: {
    draft() {
      this.cascade();
    },
  },
  mounted() {
    // Установка минимальной высоты блока изображения до получения исходника
    // Для мобильных устройств, адаптивная загрузка
    this.cascade();
    // Каскадная загрузка файлов изображений, начиная с размытого
    // Худшего качества и стандартное с ватермаркой
  },
  computed: {
    subdir() {
      let sub = [];
      let {alias} = this.item;
      sub.push(alias.substr(0, 2));
      sub.push(alias.substr(2, 2));
      return sub.join('/') + '/';
    },
    ratio() {
      return this.$store.state.clientWidth / this.item.cell[0];
    },
    // Так ли необходима загрузка лучшего качества
    huge() {
      return this.full && this.item.ext != 'gif' && this.item.width > 700;
    },
  },
  methods: {
    cascade() {
      let delay = this.setBlur();
      if (this.draft) {
        return 0;
      }
      setTimeout(() => {
        delay = this.setBlear();
        setTimeout(() => {
          this.setPreview();
          this.setOverview();
        }, delay);
      }, delay);
    },

    resourceUrl(resource, ext) {
      let uri = config.IMG_CDN + '/resources/';
      let path = this.subdir + this.item.alias;
      return `${uri}${resource}/${path}.${ext}`;
    },
    setBlur() {
      let {delay, enabled} = this.sources.blur;
      this.sources.blur.src = enabled ? this.resourceUrl('blur', 'png') : '';
      return delay;
    },
    setBlear() {
      let {delay, enabled} = this.sources.blear;
      this.sources.blear.src = enabled ? this.resourceUrl('blear', 'png') : '';
      return delay;
    },
    setPreview() {
      let {delay, enabled} = this.sources.preview;
      this.sources.preview.src = enabled ? this.resourceUrl('preview', this.item.ext) : '';
    },
    setOverview() {
      let {delay, enabled} = this.sources.overview;
      let filter = this.huge ? 'overview' : 'preview';
      this.sources.overview.src = enabled ? this.resourceUrl(filter, this.item.ext) : '';
    },
    imageSize() {
      return this.full
        ? {}
        : {
          maxWidth: `${this.item.cell[0]}px`,
          maxHeight: `${this.item.cell[1]}px`,
        };
    },

    background() {
      let src = [
        `url('${this.sources.blear.src}')`,
        `url('${this.sources.blur.src}')`,
      ];
      if (this.full) {
        src.unshift(`url('${this.sources.preview.src}')`)
      }
      // Ratio by clientWidth for small device|mobile
      let gif = /.*gif$/.test(this.sources.overview.src);
      let margin = -10.4 * 600/this.item.width;
      return {
        backgroundImage: src.join(', '),
        minHeight: this.item.cell[1] * this.ratio + 'px',
        marginBottom: (gif ? 0 : margin) + 'px',
      };
    },
  },
}
</script>

<template>
  <div class="progressive-image" :style="background()">
    <img :src="sources.overview.src" @click="$emit('close')" @load="$emit('load')" :style="imageSize()"/>
  </div>
</template>

<style lang="less">
.progressive-image {
  // min-height: 300px;
  // position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  // overflow: hidden;

  img {
    width: 100%;
  }
}
</style>
