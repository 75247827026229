import Api from '../assets/libs/rest/Api';
import config from './index';

const resouses = [];

resouses.default = {
  host: config.API_URL,
  delay: config.NET_DELAY,
  prefix: 'api',
  version: '',
  routing: {},
};

resouses.images = {
  prefix: '',
  routing: {
    cget: 'popular/{next}',
  },
};

resouses.token = {
  routing: {
    route: '',
    post: 'login_check',
  },
};

resouses.tags = {
  routing: {
    get: 'popular',
    cget: '{query}',
  },
};

const api = new Api(resouses);

// const retryAttempt = false;

// axios.interceptors.response.use(response => response, (error) => {
//   // retry the request that errored out
//   if (error.response.status === 401) {
//     if (retryAttempt) {
//       global.App.$root.unauthorized();
//     } else {
//       retryAttempt = true; // now it can be retried
//       return global.App.$root.refresh().then(() => axios(error.config));
//     }
//   }
//   return Promise.reject(error);
// });

export default api;
