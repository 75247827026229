<script>
import Content from '../dialogs/Content.vue';
import HeaderImage from '../modules/HeaderImage.vue';
import Tags from './Tags.vue';
import TagsQueryList from '../TagsQueryList.vue';

export default {
  props: ['photo'],
  components: {
    Content,
    HeaderImage,
    TagsQueryList,
    Tags,
  },
  data: () => ({
    added: [],
    query: '',
    waiting: false,
    activeTab: 'search',
  }),
  mounted() {
    console.log('def key', this.$api.default('key'));
  },
  computed: {
    offer() {
      return this.query && !this.waiting && !this.tags.length;
    },
  },
  methods: {
    select(item) {
      this.$api.res('folders').post({
        id_tag: item.id,
        id_photo: this.photo.id,
      });
    },
    save() {
      this.selected = this.selected == false;
      setTimeout(() => {
        // this.warning = this.selected == false;
        this.selected = false;
      }, 3000);
    },
    add(title) {
      this.added.push(title);
      console.log('add', this.added);
    },
    close() {
      alert('close');
    },
  },
};
</script>

<template>
  <Content @close="$emit('close')">
    <div class="dialog-save">
      <div class="">
        <HeaderImage :path="photo.path" @close="$emit('close')"/>
        <div class="tags-search">

          <div class="tags-search__added" :class="{'tag-list-is-empty': !added.length}">
            <Tags v-if="added.length" :tags="added"/>
          </div>

          <div class="tags-search__form">
            <input class="form-control" type="text" placeholder="Введите название"
              :value="query" @keyup="query = $event.target.value">
            <div class="tags-search__tabs">
              <span @click="activeTab = 'search'" :class="{'active-tab': activeTab == 'search'}">
                Все
              </span> |
              <span @click="activeTab = 'favorite'" :class="{'active-tab': activeTab == 'favorite'}">
                Мои
              </span>
            </div>

          </div>

          <TagsQueryList :auto="true" :query="query"
            @select="select"/>

          <!-- <div class="tags-search__list">
            <SearchTagItem v-for="(item, index) in tags" :key="index"
            :item="item" :photo="photo" @select="add"/>

            <SearchTagItem v-if="offer" :key="offer" :title="query" :offer="true" @select="add"/>
          </div> -->

        </div>
      </div>
    </div>

  </Content>
</template>

<style lang="less">
.dialog-save {
  display: flex;
  flex: 1 1 auto;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
}
.dialog-options {
  margin: 10px 10px;
}

.tags-search {
  position: relative;
  padding: 10px 15px 15px;

  &__tabs {
    position: absolute;
    right: 0;
    top: 0;
    padding: 7px 10px;
    // background: #e6e6e6;
    font-size: 16px;
    color: #999;
    cursor: pointer;
    user-select: none;

    .active-tab {
      color: #444;
    }

    i {
      margin: 0 5px 0 5px;
    }
  }

  &__added {
    margin-bottom: 3px;
    &.tag-list-is-empty {
      margin-bottom: 33px;
    }
  }
  &__list {
    // margin: 15px 0px 15px;
  }
  &__form {
    position: relative;
    margin: 0px 0px 10px;
  }
  &__count {
    color: #999;
    text-align: center;
    padding: 10px;
  }
}
</style>
