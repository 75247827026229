<script>
export default {
  props: ['item', 'offer', 'simple'],
  data: () => ({
    selected: false,
  }),
  watch: {
    item() {
      this.selected = false;
    },
  },
  computed: {
    type() {
      return {
        variant: !this.selected,
        selected: this.selected,
      };
    },
    icon() {
      return this.selected ? 'fa-check' : 'fa-plus';
    },
    color() {
      return this.selected ? 'tag-selected' : 'tag-variant';
    },
  },
  methods: {
    select() {
      this.selected = this.selected == false;
      this.$emit('select');
    },
  },
};
</script>

<template>
  <div class="tags-search__item" :class="color">
    <span @click="select">
      <i class="fas" :class="icon" v-if="!simple"></i>
      {{item.title}}
    </span>

    <i class="fas fa-angle-right ml-auto" @click="$emit('append')"></i>
  </div>
</template>

<style lang="less">
.tags-search {
  &__item {
    display: flex;
    align-items: center;
    font-size: 16px;
    padding: 5px 10px;
    margin-bottom: 0px;

    color: #666;
    text-transform: uppercase;
    border-bottom: 1px solid #f2f2f2;
    // background: #f5f5f5;
    border-radius: 3px;
    cursor: pointer;

    &:last-child {
      border-width: 0;
    }

    i.fas {
      min-width: 16px;
      height: 16px;
      opacity: 0.3;
      margin-right: 3px;
      line-height: 1;
      text-align: center;
    }

    &.tag {
      &-variant {
        background: #fff;
      }
      &-selected {
        background: var(--light);
        color: #ccc;
      }
      &-waiting {
        background: var(--light);
      }
      &-offer {
        // background: #66ccff;
      }
    }
  }
}
</style>
