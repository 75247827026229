import _ from 'underscore';

export default {
  namespaced: true,
  state: {
    list: [],
  },
  mutations: {
    load(state, images) {
      state.list = images;
    },
    push(state, images) {
      state.list = _.union(state.list, images);
    },
    reset(state) {
      state.list = [];
    },
  },
};
