<script>
import SearchTagItem from './SearchTagItem.vue';

export default {
  props: ['auto', 'query', 'simple'],
  components: {
    SearchTagItem,
  },
  data: () => ({
    tags: [],
  }),
  watch: {
    query(val) {
      val && this.search();
    },
    auto(val) {
      if (!val && this.tags.length) {
        this.tags = [];
      } else {
        val && this.load();
      }
    },
  },
  mounted() {
    this.auto && this.load();
  },
  computed: {
    offer() {
      return this.query && !this.waiting && !this.tags.length;
    },
  },
  methods: {
    load() {
      this.$api
        .res('tags')
        .get()
        .then(({data}) => {
          this.tags = data;
        });
    },
    search() {
      this.$api
        .res('tags')
        .cget({query: this.query})
        .then(({data}) => {
          this.tags = data;
        });
    },
    // add(title) {
    //   this.added.push(title);
    //   console.log('add', this.added);
    // },
    // close() {
    //   alert('close');
    // },
  },
};
</script>

<template>
  <transition name="slide">
    <div class="tags-search__list" v-if="tags.length">
      <SearchTagItem v-for="(item, index) in tags" :key="index"
       :item="item" :simple="simple"
       @select="$emit('select', item)"
       @append="$emit('append', item.title)"/>

      <!-- <SearchTagItem v-if="offer" :key="offer"
        :title="query"
        :offer="true"
        @select="$emit('select', item)"/> -->
    </div>
  </transition>
</template>

<style lang="less">
.tags-search {
  &__list {
    // margin: 15px 0px 15px;
  }
}
</style>
