<script>
export default {
  props: [],
  data: () => ({

  }),
};
</script>

<template>
  <div class="image-users">
    <span class="user-avatar" style="background-color: #6712c9;"></span>
    <span class="user-avatar" style="background-color: #64be2f"></span>
    <span class="user-avatar" style="background-color: #809cb1"></span>
    <span class="user-avatar" style="background-color: #3eb530"></span>
    <span class="user-avatar" style="background-color: #984ea1"></span>
    <span class="user-avatar" style="background-color: #48819e"></span>
    <span class="user-avatar" style="background-color: #229607"></span>
    <span class="user-avatar" style="background-color: #1409a8"></span>
    <span class="user-avatar" style="background-color: #731475"></span>
    <span class="user-avatar" style="background-color: #ff8f28"></span>
  </div>
</template>

<style lang="less">
.image-users {
  padding: 0 0 10px;
  font-size: 0;
}

.user-avatar {
  padding: 16px;
  display: inline-block;
  border-radius: 20px;

  margin-right: 2px;
}
</style>
