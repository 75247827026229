<script>
import BottomBar from './BottomBar';

export default {
  props: [],
  components: {
    BottomBar,
  },
  data: () => ({
    auth: false,
  }),
};
</script>

<template>
  <BottomBar>
    <div class="bottom-nav"  @click="$emit('close')">
      <div class="nav-button" @click="$router.push({name: 'collection'})">
        <div class="nav-button__icon">
          <i class="fas fa-folder-open"></i>
        </div>
        <div class="nav-button__caption">
          Сохранено
        </div>
      </div>

      <div class="nav-button" @click="$router.push({name: 'liked'})">
        <div class="nav-button__icon">
         <i class="fas fa-heart"></i>
          <!-- <span class="nav-button__status active"></span> -->
        </div>
        <div class="nav-button__caption">
          Нравится
        </div>
      </div>


    </div>
  </BottomBar>
</template>

<style lang="less">
.bottom-nav {
  display: flex;
  justify-content: space-around;
  // align-items: center;
  white-space: nowrap;
  overflow: hidden;

  max-width: @activity-width;
  margin: 0 auto;

  padding: 7px @indent-sm 2px;

  @media (max-width: @min-display) {
    padding: 7px @indent-sm;
  }

  .nav-button {
    // flex: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    max-width: 120px;
    white-space: nowrap;
    overflow: hidden;
    padding: 0px 5px;
    position: relative;

    color: @dark-light;
    cursor: pointer;

    &__icon {
      flex: 0 auto;
      font-size: 20px;
      line-height: 1;
      margin-bottom: 1px;
      position: relative;
      color: @dark-light;

      &-text {
        padding-top: 5px;
        font-size: 20px;
      }
    }

    &__status {
      width: 10px;
      height: 10px;
      position: absolute;
      bottom: 0px;
      right: -3px;
      display: inline-block;
      border-radius: 10px;
      box-shadow: 0 0 0 2px #fff;
      vertical-align: middle;
      background-color: @red-dark;
    }

    &__caption {
      flex: 0 auto;
      font-size: 12px;
      @media (max-width: @min-display) {
        display: none;
      }
    }
  }
}
</style>
