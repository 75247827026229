<script>
import HeaderImage from '@/components/modules/HeaderImage.vue';
import Content from './Content.vue';

export default {
  props: [],
  components: {
    HeaderImage,
    Content,
  },
  data: () => ({
    color: '#47d722',
    loading: true,
    user: {
      username: '',
      email: '',
      password: '',
      id: '',
    },
  }),
  mounted() {
    this.loading = true;
    this.$api
      .res('emotions')
      .get({id: this.$store.state.token.id}, 'users/{id}')
      .then(({data}) => {
        this.loading = false;
        this.user = data;
        console.log(data);
      })
      .catch(() => {
        this.loading = false;
      });
  },
  computed: {
    login() {
      return this.user.username || this.user.id;
    },
  },
  methods: {
    save() {
      this.$api
        .res('emotions')
        .patch({
          username: this.$store.state.token.username,
          email: this.user.email,
        },
        {
          id: this.user.id,
        },
        'users/{id}')
        .then(({data}) => {
          this.loading = false;
          this.user = data;
          console.log(data);
        })
        .catch(() => {
          this.loading = false;
        });
    },
    close() {
      this.$emit('close');
    },
    logout() {
      this.$emit('logout');
      this.close();
    },
  },
};
</script>

<template>
  <Content :compact="true" @close="close">

          <HeaderImage :path="null" :color="'#d74822'" :iSstatic="true"/>

          <div class="option">
            <div class="btn btn-secondary" @click="logout">Выйти</div>
          </div>

          <div class="board content wrapped">
            <div class="settings-form">
              <div class="settings-form__tile">
                <label for="">Логин</label>
                <input type="text" class="form-control" v-model="user.username" @blur="save">
              </div>
              <div class="settings-form__tile">
                <label for="">Пароль</label>
                <input type="text" class="form-control" v-model="user.password" @blur="save">
              </div>
              <div class="settings-form__tile">
                <label for="">Емаил</label>
                <input type="text" class="form-control" v-model="user.email" @blur="save">
              </div>
              <!-- <div class="settings-form__tile">

              </div> -->
            </div>
          </div>
  </Content>
</template>

<style lang="less">
.option {
  position: absolute;
  top: 10px;
  right: 10px;
}
// padding: 10px 15px;

.settings-form {
  max-width: 300px;

  label {
    font-size: 18px;
    font-weight: bold;
    color: #555;
  }

  &__tile {
    margin-bottom: 15px;
  }
}
</style>
