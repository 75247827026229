<script>
import _ from 'underscore';
import Summary from './Summary.vue';
import Users from './Users.vue';
import Options from './Options.vue';
import Tags from './Tags.vue';
import SaveDialog from './SaveDialog.vue';
import ProgressiveImage from './ProgressiveImage.vue';
import ImageStatus from './ImageStatus.vue';

export default {
  props: ['item', 'full', 'draft'],
  components: {
    Summary,
    Users,
    Options,
    Tags,
    SaveDialog,
    ProgressiveImage,
    ImageStatus,
  },
  data() {
    return {
      liked: false,
      save: false,
      bad: false,
      wait: true,
      error: false,
    };
  },
  methods: {
    tags(items) {
      return _.pluck(items, 'tag');
    },
    like() {
      const data = {
        user_id: 1,
        image_id: this.id,
      };
      this.$api.res('images/like').post(data).then(() => {
        console.log('liked');
        this.liked = true;
      });
    },
    onload() {
      this.wait = false;
      // this.error = true;
    },
    close() {
      this.$emit(this.full ? 'close' : 'view');
    },
  },
};
</script>

<template>
  <div class="image-card" :key="item.id">
    <div class="image-card__source" :data-id="item.id">
      <ProgressiveImage
       :item="item"
       :full="full"
       :draft="draft"
       @load="onload"
       @close="close()"/>

      <ImageStatus :item="item" :wait="wait" :error="error"/>

      <transition name="fade">
        <div class="bad-source" v-if="bad" @click="close()"></div>
      </transition>
    </div>
    <Summary :rank="item.rank" :quality="item.quality" :width="item.width" :height="item.height" />

    <div class="image-card__body">
      <Options :photo="item" @save="save = item.id" @bad="bad = bad == false" />

      <div class="" style="max-width: 550px;">
        <Users v-if="0" />
        <Tags :tags="tags(item.tags)" :default="10" />
      </div>

      <SaveDialog v-if="save == item.id" :photo="item" @close="save = false" />
    </div>
  </div>
</template>

<style lang="less">
.image-card {
  background-color: white;
  border-radius: 2px;
  margin-bottom: 5px;

  &__source {
    font-size: 0px;
    position: relative;
    // border-top: 1px solid #f2f2f2;

    .bad-source {
      position: absolute;
      background: rgba(#fff, 0.9);
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__body {
    padding: 15px 10px 10px;
    position: relative;
    background-color: white;
  }
}
</style>
