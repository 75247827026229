<script>
import JWTDecode from 'jwt-decode';
import randomatic from 'randomatic';
import Content from './Content.vue';
import CreateUser from './CreateUser.vue';
// import Loader from '../Loader.vue';

export default {
  props: [],
  components: {
    Content,
    CreateUser,
    // Loader,
  },
  data: () => ({
    approve: false,
    exceed: false,
    wait: true,
    status: '',
    registrationToken: null,
    recaptchaToken: null,
  }),
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.timeout = 0;
      this.wait = true;
      this.exceed = false;
      this.status = 'Подождите...';
      global.recaptcha.execute().then((token) => {
        this.recaptchaToken = token;
        this.attempt();
      });
    },
    hold(time) {
      this.status = 'Подготовка участника...';
      setTimeout(() => {
        this.wait = false;
        this.status = 'Готово, жмите скорее!';
      }, time * 1000);
    },
    attempt() {
      this.password = randomatic('aA0', 6);
      this.$api.res('registration').load().then(({data}) => {
        const payload = JWTDecode(data);
        console.log('token', payload);
        this.hold(payload.time);
        this.registrationToken = data;
        this.await(payload.exp);
      });
    },
    await(timeout) {
      const time = timeout - Math.floor(Date.now() / 1000);
      setTimeout(() => {
        this.exceed = true;
      }, time * 1000);
    },
    submit() {
      this.approve = true;
    },
  },
};
</script>

<template>
  <div class="">
    <Content :wrapped="true" :compact="false" @close="$emit('close')" v-if="!approve">
      <h1>Простая регистрация</h1>
      <p>Начните. Просто в один клик! Бесплатно и без СМС. Возможны возрастные ограничения при просмотре эротического контента.</p>

      <p class="status-info text-danger" v-if="exceed">
        Время закончилось!
        <a href="#" @click.prevent="load()">
          <i class="fas fa-sync-alt"></i>
          Обновить
        </a>
      </p>
      <p class="status-info" :class="wait ? 'text-muted' : 'text-success'" v-else>
        {{status}}
      </p>

      <p class="btn-toolbar">
        <button class="btn btn-primary mr-1" :disabled="wait || exceed" @click="submit()">
          Продолжить
        </button>

        <button class="btn btn-outline-secondary" :disabled="wait" @click="$emit('close')">
          Отмена
        </button>
      </p>

      <p class="agreement-info">
        <a href="#">Пользовательское соглашение</a><br>
        <a href="#">Политика конфиденциальности</a><br>
      </p>

    </Content>

    <CreateUser :registrationToken="registrationToken" :recaptchaToken="recaptchaToken" v-else />


  </div>
</template>

<style lang="less">
.status-info {
  i {
    margin-left: 5px;
      // margin-bottom: 10px;
  }
}

.registration-form {
  .form-group:last-child {
    margin-bottom: 0;
  }
}
.registration-hint {
  text-align: center;
}
.agreement-info {
  color: #999;
  // font-size: 13px;
}
</style>
