<script>
import _ from 'underscore';
import ImageCard from './card/ImageCard';
import NextPage from './NextPage';
import ViewImage from './card/ViewImage';
import Filters from './Filters';
import Loader from './Loader';
// import Info from './Info';
import PageTagList from './PageTagList';

export default {
  props: ['page', 'tag'],
  components: {
    ImageCard,
    NextPage,
    Filters,
    // Info,
    ViewImage,
    Loader,
    PageTagList,
  },
  watch: {
    // при изменениях маршрута запрашиваем данные снова
    $route: 'reload',
  },
  data: () => ({
    title: '',
    view: false,
    batch: 10,
    loading: false,
    flash: false,
    pageCount: 0,
    rank: 1,
    days: 100000,
    end: false,
    init: false,
  }),
  computed: {
    images() {
      return this.$store.state.images.list;
    },
    order() {
      return this.$route.query.order;
    },
    uri() {
      return `images/${this.$route.name}/{next}`;
    },
    next() {
      const start = this.page != undefined ? this.page * 1 : 0;
      return start + this.batch * this.pageCount;
    },
    count() {
      return this.images.length;
    },
  },
  mounted() {
      // Установка минимальной высоты блока изображения до получения исходника
      // Для мобильных устройств, адаптивная загрузка
      this.$nextTick().then(() => {
        this.$store.dispatch('clientWidth', this.$refs.source.clientWidth);
      });
  },
  methods: {
    reset() {
      if (this.flash) {
        this.flash = false;
        window.scrollTo({top: 0});
        this.$store.commit('images/reset');
      }
    },
    load() {
      this.loading = true;
      this.$api
        .res('images')
        .cget({
          next: this.next,
          order: this.order,
          days: this.days,
          rank: this.rank,
          tag: this.tag,
        },
        this.uri)
        .then((response) => {
          this.handle(response);
        })
        .catch(() => {
          this.loading = false;
        });
    },
    initialize() {
      if (!this.init) {
        this.$store.commit('images/reset');
        this.init = true;
      }
    },
    handle(response) {
      this.loading = false;
      this.reset();
      const {images, tag} = response.data;
      if (images && images.length) {
        this.initialize();
        this.$store.commit('images/push', images);
        this.end = false;
        // this.images = images;
        this.pageCount += 1;
      } else {
        this.end = true;
      }
      this.setTitle(tag);
    },
    setTitle(tag) {
      this.title = '';
      if (tag) {
        this.title = tag.title;
      }
    },
    reload(jump) {
      this.pageCount = 0;
      this.flash = true;
      this.load();
    },
    refresh() {
      this.flash = true;
      this.$router.push({name: this.$route.name, params: {page: this.next}});
      // this.load();
    },
    // more: _.throttle(function() {
    //   this.cget();
    // }, 3000, {trailing: false}),
    more() {
      this.load();
    },

    setDays(val) {
      this.days = val;
      this.rank = 100;
      this.reload();
    },
    setRank(val) {
      this.rank = val;
      this.days = 100000;
      this.reload();
    },
  },
  created() {
    this.load();
  },
};
</script>

<template>
  <div id="container">
    <div class="board" ref="source">

      <!-- <Info /> -->

      <Filters :order="order" :rank="rank" :days="days" :title="title"/>

      <div class="splash-loader" v-if="pageCount == 0 && !count">
        <Loader/>
      </div>

      <div class="image-list">
        <ImageCard v-for="item in images" :key="item.id"
        :id="item.id"
        :item="item"
        :draft="!init"
        @view="view = item"/>

        <div class="alert alert-warning" v-if="end">
          Все фотографии загружены. Измените фильтры, чтобы загрузить ещё больше разных фотографий, или измените раздел.
        </div>
      </div>

      <div class="update-mask" v-if="flash">
        <i class="fas fa-ellipsis-h"></i>
      </div>
    </div>

    <NextPage
     :waiting="loading"
     :next="next"
     @force="refresh()"
     @more="more()"/>

    <PageTagList :images="images"/>

    <ViewImage v-if="view"
     :item="view"
     @close="view = false"/>
  </div>
</template>

<style lang="less">
.image-list {
  margin-bottom: 15px;
}

.update-mask {
  font-size: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: #999;
  background: rgba(#fff, 0.55);
}

.splash-loader {
  min-height: 500px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.end-alert {
  padding: 10px;
  margin-bottom: 15px;
}
</style>
