<script>
import ImageCard from './ImageCard.vue';

export default {
  name: 'FullView',
  props: ['item'],
  components: {
    ImageCard,
  },
  data: () => ({
    save: false,
  }),
};
</script>

<template>
  <div class="view-image" @click.self="$emit('close')">
    <div class="view-image__wrapper" @click.self="$emit('close')">
      <ImageCard :full="true" :item="item" @close="$emit('close')"/>
    </div>
  </div>
</template>

<style lang="less">
.view-image {
  background: rgba(#000, 0.95);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  overflow-y: auto;
  height: 100%;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  &__wrapper {
    background: #fff;

    @media screen and (max-width: @activity-width) {
      width: 100%;
    }

    @media screen and (min-width: @activity-width) {
      min-width: @activity-width;
    }
  }
}
</style>
